import { Error404Page } from 'maven-ui-kit/core';

import { useCustomRouter } from 'hooks/useCustomRouter.hook';

import { Links } from 'types/links';

const NotFound = () => {
  const { push } = useCustomRouter();

  return (
    <Error404Page
      onRedirect={() => {
        push(Links.default);
      }}
    />
  );
};

export default NotFound;
